<template>
  <button
    :type="type"
    class="tw-provider__button"
    :class="{
      'tw-dark': darkMode,
      'tw-provider__button--disabled': disabled && !loading,
      'tw-provider__button--loading': loading
    }"
    :disabled="disabled || loading"
  >
    <template v-if="loading">
      <WebSpinner
        :style="spinnerStyle"
        size="20"
        color="transparent"
        background="currentColor"
        class="mr-[10px]"
      />
    </template>
    <img
      :src="getAssetFromCdn(`editor/payment-providers/${provider}${disabled && !loading ? '_disabled' : darkMode ? '_black' : ''}.png`)"
      :alt="provider"
    />
  </button>
</template>

<script lang="ts" setup>
import { type PropType, computed } from 'vue';
import { PaymentProvider } from '@shared/elements/common/payment/types';
import { WebSpinner } from '@shared/components';
import { getAssetFromCdn } from '@shared/utils/helpers';

const props = defineProps({
  type: { type: String as PropType<'button' | 'submit'>, default: 'button' },
  provider: { type: String as PropType<PaymentProvider>, default: PaymentProvider.PAYPAL },
  disabled: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  darkMode: { type: Boolean, default: false },
});

const spinnerStyle = computed(() => {
  if ([PaymentProvider.APPLE_PAY, PaymentProvider.GOOGLE_PAY].includes(props.provider)) {
    return {
      color: props.darkMode ? 'black' : 'white'
    }
  }
  return null;
})
</script>

<style lang="postcss" scoped>
.tw-provider__button {
  @apply flex h-48 w-full items-center justify-center rounded-[6px] bg-[#000] p-8;
  img {
    @apply max-h-20;
  }

  &.tw-dark {
    @apply bg-white;
  }

  &.tw-provider__button {
    &--disabled {
      @apply !bg-neutral-200 !cursor-not-allowed;
    }
    &--loading {
      @apply !cursor-not-allowed;
    }
  }
}
</style>
